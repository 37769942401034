import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import buildHasuraProvider from "ra-data-hasura-graphql";
import { Admin, Resource } from "react-admin";
import {
  UserList,
  UserCreate,
  UserShow,
  UserEdit,
} from "./../components/users";
import { OpticalEdit, OpticalCreate } from "./../components/optics";
import authProvider from "./../authProvider";
import Loader from "react-loader-spinner";

const ReactAdmin = () => {
  const [dataProvider, setDataProvider] = useState();

  useEffect(() => {
    const client = new ApolloClient({
      link: new HttpLink({
        uri: "https://sure-newt-53.hasura.app/v1/graphql",
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
        },
      }),
      cache: new InMemoryCache(),
    });
    buildHasuraProvider({ client })
      .then((provider) => setDataProvider(() => provider))
      .catch((err) => console.error(err));
  }, []);

  if (!dataProvider) {
    return (
      <div style={{ textAlign: "center", height: "100vh", paddingTop: "10em" }}>
        <Loader type="Oval" color="#000" />
      </div>
    );
  }
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        show={UserShow}
        edit={UserEdit}
      />
      <Resource name="opticalData" edit={OpticalEdit} create={OpticalCreate} />
    </Admin>
  );
};

export default ReactAdmin;
