export const validateUserCreation = (values) => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = ["Full Name is required"];
  }

  if (!values.phone) {
    errors.phone = ["Phone Number is required"];
  } else if (
    values.phone.toString().length !== 10 ||
    !(
      values.phone.toString()[0] === "9" ||
      values.phone.toString()[0] === "8" ||
      values.phone.toString()[0] === "7" ||
      values.phone.toString()[0] === "6"
    )
  ) {
    errors.phone = ["Invalid Phone Number"];
  }
  if (!values.age) {
    errors.age = ["Age is required"];
  }
  if (!values.gender) {
    errors.gender = ["Gender is required"];
  }
  return errors;
};

export const validateOpticalDataCreation = (values) => {
  const errors = {};
  console.log(values);
  const { data: { left = {}, right = {} } = {} } = values;
  if (
    right &&
    right.distance &&
    right.near &&
    right.distance.sph &&
    right.distance.cyl &&
    right.distance.axis &&
    right.near.sph &&
    right.near.cyl &&
    right.near.axis &&
    left &&
    left.distance &&
    left.near &&
    left.distance.sph &&
    left.distance.cyl &&
    left.distance.axis &&
    left.near.sph &&
    left.near.cyl &&
    left.near.axis
  ) {
  } else {
    errors.right = ["All Fields are required"];
  }
  return errors;
};
