import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";

const PrintPage = () => {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => window.close(),
    pageStyle: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  });
  const urlParams = new URLSearchParams(
    `?${window.location.href.split("?")[1]}`
  );
  const urlData = urlParams.get("data");
  const { userData = {}, opticalData = {} } = JSON.parse(urlData);
  const { left = {}, right = {} } = opticalData.data;

  useEffect(() => {
    handlePrint();
  });

  return (
    <div>
      <ComponentToPrint
        ref={printRef}
        right={right}
        left={left}
        userData={userData}
      />
      <div align="center">
        <button onClick={handlePrint}>Print</button>
      </div>
    </div>
  );
};

class ComponentToPrint extends React.Component {
  render() {
    const { right, left, userData } = this.props;
    return (
      <div id="Main">
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossorigin="anonymous"
        />
        <div class="row">
          <div class="col">B.N. Ramaswamy,D.R.O</div>
          <div class="col" align="right">
            Phone Number : 080-41515498
          </div>
        </div>
        <div align="center">
          For Best Quality Spectacles & Contact Lens, Please Visit
        </div>
        <br />
        <div align="center">
          <h1>VINAYAKA OPTICALS</h1>
        </div>
        <br />
        <div align="center">
          <h4>
            No.3, 5th Main Road,
            <br />
            Ganganagar, Bangalore -560032
          </h4>
        </div>
        <br />
        <div align="center">
          <h3>PRESCRIPTION</h3>
        </div>
        <div
          class="container"
          style={{ paddingLeft: window.innerWidth * 0.22 }}>
          Name: {userData.fullName}
          <br />
          Phone No: {userData.phone}
          <br />
          Age: {userData.dob}
          <br />
          Sex: {userData.gender}
        </div>
        <br />
        <div class="container-fluid" id="main">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" colspan="3">
                  <div align="center">Right</div>
                </th>
                <th scope="col" colspan="3">
                  <div align="center">Left</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <center>
                    <b>SPH</b>
                  </center>
                </td>
                <td>
                  <center>
                    <b>CYL</b>
                  </center>
                </td>
                <td>
                  <center>
                    <b>AXIS</b>
                  </center>
                </td>
                <td>
                  <center>
                    <b>SPH</b>
                  </center>
                </td>
                <td>
                  <center>
                    <b>CYL</b>
                  </center>
                </td>
                <td>
                  <center>
                    <b>AXIS</b>
                  </center>
                </td>
              </tr>
              <tr>
                <th scope="row">Dist</th>
                <td>
                  <center>{right.distance.sph}</center>
                </td>
                <td>
                  <center>{right.distance.cyl}</center>
                </td>
                <td>
                  <center>{right.distance.axis}</center>
                </td>
                <td>
                  <center>{left.distance.sph}</center>
                </td>
                <td>
                  <center>{left.distance.cyl}</center>
                </td>
                <td>
                  <center>{left.distance.axis}</center>
                </td>
              </tr>
              <tr>
                <th scope="row">Near</th>
                <td>
                  <center>{right.near.sph}</center>
                </td>
                <td>
                  <center>{right.near.cyl}</center>
                </td>
                <td>
                  <center>{right.near.axis}</center>
                </td>
                <td>
                  <center>{left.near.sph}</center>
                </td>
                <td>
                  <center>{left.near.cyl}</center>
                </td>
                <td>
                  <center>{left.near.axis}</center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div align="left">WT/SP2/PGE/KRY/EX/BiFocal/CR 39/Progressive</div>
        <br />
        <br />
        <div align="right">Optometrist</div>

        <div>Date : {new Date().toLocaleDateString()}</div>
        <div id="date"></div>
      </div>
    );
  }
}
export default PrintPage;
