const commonHeader = {
  "Content-Type": "application/json",
  "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
};

export const getUsers = (ids = []) => {
  var graphql = JSON.stringify({
    query:
      "query getUsers($where: users_bool_exp) {\n  users(where: $where) {\n    phone\n    fullName\n  }\n}\n",
    variables: {
      where: {
        _or: ids.map((id) => ({ id: { _eq: id } })),
      },
    },
  });
  var opts = {
    method: "POST",
    headers: commonHeader,
    body: graphql,
  };
  return fetch("https://sure-newt-53.hasura.app/v1/graphql", opts)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
