import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useListContext } from "react-admin";
import { Button, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const DateRangeInput = () => {
  const { filterValues, setFilters } = useListContext();

  const from = filterValues["createdAt@_gte"]
    ? moment(filterValues["createdAt@_gte"]).format("D MMM YYYY")
    : null;
  const to = filterValues["createdAt@_lte"]
    ? moment(filterValues["createdAt@_lte"]).format("D MMM YYYY")
    : null;

  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      <DateRangePicker
        onApply={(event, dates) => {
          event.preventDefault();
          const { startDate, endDate } = dates;
          setFilters({
            "createdAt@_gte": startDate.toISOString(),
            "createdAt@_lte": endDate.toISOString(),
          });
        }}
      >
        <TextField
          margin="dense"
          variant="filled"
          label="Date Range"
          style={{ marginBottom: 8 }}
          value={!from || !to ? "" : `${from} - ${to}`}
        />
      </DateRangePicker>
      {filterValues && Object.values(filterValues).length ? (
        <Button
          style={{ marginLeft: 20 }}
          variant="text"
          color="primary"
          size="small"
          onClick={() => setFilters({})}
        >
          <ClearIcon style={{ marginRight: 5 }} />
          Clear
        </Button>
      ) : null}
    </div>
  );
};

export default DateRangeInput;
