import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import PrintPage from "./pages/PrintPage";
import ReactAdmin from "./pages/ReactAdmin";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
require("dotenv").config();

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/print">
          <PrintPage />
        </Route>
        <Route path="/admin">
          <ReactAdmin />
        </Route>
        <Route path="/">
          <Redirect to="/admin" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
