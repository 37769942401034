import { FirebaseAuthProvider } from "react-admin-firebase";

const config = {
  apiKey: "AIzaSyCWYlrGfZmR62RsZ-v0nNSp-viB_U7UH60",
  authDomain: "vinayaka-opticals.firebaseapp.com",
  databaseURL: "https://vinayaka-opticals.firebaseio.com",
  projectId: "vinayaka-opticals",
  storageBucket: "vinayaka-opticals.appspot.com",
  messagingSenderId: "846600423956",
  appId: "1:846600423956:web:605e7f9c3218f3c0b93501",
  measurementId: "G-G97X73VV90",
};

const options = {
  persistence: "local",
};

export default FirebaseAuthProvider(config, options);
