import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { TextInput } from "react-admin";
import PropTypes from "prop-types";

const OpticalDataInput = () => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colspan="3" align="center">
              Right
            </TableCell>
            <TableCell colspan="3" align="center">
              Left
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">SPH</TableCell>
            <TableCell align="center">CYL</TableCell>
            <TableCell align="center">AXIS</TableCell>
            <TableCell align="center">SPH</TableCell>
            <TableCell align="center">CYL</TableCell>
            <TableCell align="center">AXIS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Distance</TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.distance.sph" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.distance.cyl" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.distance.axis" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.distance.sph" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.distance.cyl" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.distance.axis" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Near</TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.near.sph" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.near.cyl" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.right.near.axis" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.near.sph" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.near.cyl" />
            </TableCell>
            <TableCell align="center">
              <TextInput label="" source="data.left.near.axis" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OpticalDataInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default OpticalDataInput;
