import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";

const OpticalDataField = ({ record = {}, source }) => {
  const { left = {}, right = {} } = record[source];
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colspan="3" align="center">
              Right
            </TableCell>
            <TableCell colspan="3" align="center">
              Left
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">SPH</TableCell>
            <TableCell align="center">CYL</TableCell>
            <TableCell align="center">AXIS</TableCell>
            <TableCell align="center">SPH</TableCell>
            <TableCell align="center">CYL</TableCell>
            <TableCell align="center">AXIS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Distance</TableCell>
            <TableCell align="center">{right.distance.sph}</TableCell>
            <TableCell align="center">{right.distance.cyl}</TableCell>
            <TableCell align="center">{right.distance.axis}</TableCell>
            <TableCell align="center">{left.distance.sph}</TableCell>
            <TableCell align="center">{left.distance.cyl}</TableCell>
            <TableCell align="center">{left.distance.axis}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">Near</TableCell>
            <TableCell align="center">{right.near.sph}</TableCell>
            <TableCell align="center">{right.near.cyl}</TableCell>
            <TableCell align="center">{right.near.axis}</TableCell>
            <TableCell align="center">{left.near.sph}</TableCell>
            <TableCell align="center">{left.near.cyl}</TableCell>
            <TableCell align="center">{left.near.axis}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OpticalDataField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default OpticalDataField;
