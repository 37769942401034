import React from "react";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";

const PrintButton = (props) => {
  const { record = {}, userData } = props;
  return (
    <Button
      color="primary"
      onClick={() =>
        window.open(
          `/print?data=${encodeURIComponent(
            JSON.stringify({ userData, opticalData: record })
          )}`,
          "_blank",
          "width=900,height=900,location=no,left=200px"
        )
      }>
      <PrintIcon style={{ paddingRight: 10 }} />
      Print
    </Button>
  );
};

export default PrintButton;
