import React, { Fragment, useState } from "react";
import {
  List,
  Create,
  Show,
  Edit,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  SimpleForm,
  SelectInput,
  NumberInput,
  SimpleShowLayout,
  ReferenceManyField,
  FunctionField,
  EditButton,
  DeleteButton,
  Filter,
  useShowController,
  BulkDeleteButton,
  Button as RAButton,
} from "react-admin";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import SmsIcon from "@material-ui/icons/Sms";
import { Button } from "@material-ui/core";
import OpticalDataField from "./OpticalDataField";
import { validateUserCreation } from "./../validations";
import PrintButton from "./PrintButton";
import DateRangeInput from "./DateRangeInput";
import SendBulkSMSModal from "./SendBulkSMS";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="phone" label="Phone No" alwaysOn />
    <TextInput source="fullName" label="Name" alwaysOn />
    <DateRangeInput alwaysOn />
  </Filter>
);

const PostBulkActionButtons = (props) => {
  const { selectedIds } = props;
  const [open, setOpen] = useState();
  return (
    <Fragment>
      <RAButton label="Send Bulk SMS" onClick={() => setOpen(true)} {...props}>
        <SmsIcon />
      </RAButton>
      <SendBulkSMSModal
        open={open}
        handleClose={() => setOpen(false)}
        ids={selectedIds}
        title="Send Bulk SMS"
      />
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

const UserTitle = ({ record }) => (
  <span>{record ? `${record.fullName}` : ""}</span>
);

const OpticalDeleteButton = (props) => {
  const { record } = props;
  return <DeleteButton {...props} redirect={`/users/${record.userId}/show`} />;
};

export const UserList = (props) => (
  <List
    {...props}
    filters={<UserFilter />}
    bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="show">
      <TextField source="fullName" />
      <TextField source="phone" />
      <TextField source="age" />
      <TextField source="gender" />
      <DateField source="createdAt" label="Created At" showTime={true} />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show" validate={validateUserCreation}>
      <TextInput source="fullName" />
      <NumberInput source="phone" max={10} min={10} />
      <NumberInput source="age" />
      <SelectInput
        source="gender"
        choices={[
          { id: "MALE", name: "MALE" },
          { id: "FEMALE", name: "FEMALE" },
          { id: "OTHER", name: "OTHER" },
        ]}
      />
    </SimpleForm>
  </Create>
);

export const UserShow = (props) => {
  const { record } = useShowController(props);

  return (
    <Show title={<UserTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="fullName" />
        <TextField source="phone" />
        <TextField source="age" />
        <TextField source="gender" />
        <ReferenceManyField
          label="Optical Data"
          reference="opticalData"
          target="userId">
          <Datagrid {...props}>
            <OpticalDataField source="data" />
            <PrintButton userData={record} />
            <EditButton />
            <OpticalDeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <FunctionField
          label=" "
          render={(record) => (
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                props.history.push(`/opticalData/create?userId=${record.id}`);
              }}>
              <LibraryAddIcon style={{ marginRight: 10 }} />
              Add Optical Data
            </Button>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm redirect="show" validate={validateUserCreation}>
      <TextInput source="fullName" />
      <NumberInput source="phone" max={10} min={10} />
      <NumberInput source="age" />
      <SelectInput
        source="gender"
        choices={[
          { id: "MALE", name: "MALE" },
          { id: "FEMALE", name: "FEMALE" },
          { id: "OTHER", name: "OTHER" },
        ]}
      />
      <ReferenceManyField
        label="Optical Data"
        reference="opticalData"
        target="userId">
        <Datagrid {...props}>
          <OpticalDataField source="data" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      <FunctionField
        label=" "
        render={(record) => (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              props.history.push(`/opticalData/create?userId=${record.id}`);
            }}>
            Add Optical Data
          </Button>
        )}
      />
    </SimpleForm>
  </Edit>
);
