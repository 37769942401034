import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { getUsers } from "../api";
import Loader from "react-loader-spinner";

const SendBulkSMSModal = ({ open, handleClose, ids, title }) => {
  const [loading, setloading] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setmessage] = useState();
  const handleBulkSMS = () => {
    setloading(true);
    getUsers(ids).then((resp) => {
      setloading(false);
      if (resp && resp.data && resp.data.users) {
        setUsers(resp.data.users);
      }
    });
  };
  const sendSMS = () => {
    const phoneArr = users.map((i) => i.phone);
    console.log(phoneArr, message);
  };
  useEffect(() => {
    handleBulkSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {loading ? (
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Loader type="Oval" color="#000" />
        </DialogContent>
      ) : (
        <div>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              multiline
              label="Message content"
              type="text"
              fullWidth
              rows={4}
              onChange={(e) => setmessage(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <Button
              onClick={sendSMS}
              color="primary"
              disabled={!(message && message.length > 5)}>
              Send
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default SendBulkSMSModal;
