import React from "react";
import {
  Create,
  Edit,
  TextInput,
  SimpleForm,
  FormDataConsumer,
} from "react-admin";
import OpticalDataInput from "./OpticalDataInput";
import { validateOpticalDataCreation } from "./../validations";

const redirect = (basePath, id, data) => `/users/${data.userId}/show`;

export const OpticalEdit = (props) => (
  <Edit title="Update Optical Data" {...props}>
    <SimpleForm redirect={redirect} validate={validateOpticalDataCreation}>
      <OpticalDataInput source="data" />
    </SimpleForm>
  </Edit>
);

export const OpticalCreate = (props) => {
  const urlParams = new URLSearchParams(
    `?${window.location.href.split("?")[1]}`
  );
  const userId = urlParams.get("userId");
  return (
    <Create title="Create Optical Data" {...props}>
      <SimpleForm redirect={redirect} validate={validateOpticalDataCreation}>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <TextInput
              label="ID"
              source="userId"
              initialValue={userId}
              disabled
              {...rest}
            />
          )}
        </FormDataConsumer>
        <OpticalDataInput source="data" />
      </SimpleForm>
    </Create>
  );
};
